export const transparentHeader = {
  transitionProperty: "background-color , opacity",
  opacity: 1,
  transitionDuration: "1s",
  transitionTimingFunction: "liear",
  backgroundColor: "transparent",
};

export const whiteColorHeader = {
  opacity: 1,
  transition: "color 0.5s linear",
  color: "white",
};

export const blackColorHeader = {
  opacity: 1,
  transition: "color 0.5s linear",
  color: "black",
};

export const scrollTopHeaderColor = {
  backgroundColor: "#fff",
  backgroundImage: "none",
  height: 60,
  transitionProperty: "background-color , opacity",
  transitionDuration: "0.5s",
  transitionTimingFunction: "ease-in",
};

export const scrollBottomHeaderColor = {
  backgroundColor: "transparent",
  transitionProperty: "background-color , opacity",
  transitionDuration: "1s",
};

export const dropDownHeader = {
  height: 300,
  backgroundImage:
    "linear-gradient(rgba(255, 255, 255), rgba(255,255, 255,0.9) , rgba(255,255, 255,0.9) ,rgba(255,255, 255,0.8) ,rgba(255, 255, 255, 0.7) , rgba(255,255, 255,0.6) , rgba(255, 255, 255,0.5), rgba(255, 255, 255, 0.4) , rgba(255, 255, 255, 0.3) ,rgba(255, 255, 255, 0.2) , rgba(255, 255, 255, 0.1) , rgba(255,255, 255,0) )",
};

export const dropDownPath = {
  "/": "OVERVIEW",
  "/about/": "ABOUT",
};

export const dropDownItem = [
  { to: "/", label: "OVERVIEW" },
  { to: "/about", label: "ABOUT" },
];
