import React, { useState, useEffect } from "react";
import { Dropdown, ListGroup, ListGroupItem } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Hamburger from "hamburger-react";
import {
  transparentHeader,
  whiteColorHeader,
  blackColorHeader,
  scrollTopHeaderColor,
  scrollBottomHeaderColor,
  dropDownHeader,
  dropDownPath,
  dropDownItem,
} from "../../contant/index";
import $ from "jquery";

const Header = ({ grad, path }) => {
  const [openHamburger, setOpenHamburger] = useState(false);
  const [stylesHeader, setStylesHeader] = useState(transparentHeader);
  const [color, setColor] = useState(
    grad ? whiteColorHeader : blackColorHeader
  );
  const [stylesBack, setStylesBack] = useState({
    opacity: "0",
  });

  const [gradient, setGradient] = useState({});
  const [open, setOpen] = useState(false);
  const goBackUp = () => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  };

  useEffect(() => {
    if (typeof window !== "undefined" && !openHamburger) {
      let lastlastScrollPos = 0;

      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
       
        setOpen(false);
        if (lastlastScrollPos > currentScrollPos && currentScrollPos > 100) {
          setStylesHeader(scrollTopHeaderColor);
          setColor(blackColorHeader);
        } else if (
          lastlastScrollPos < currentScrollPos &&
          currentScrollPos > (grad ? 500 : 100)
        ) {
          setStylesHeader({
            opacity: 0,
            transitionProperty: "opacity",
            transitionDuration: "2s",
          });
          setColor({ ...color, color: "transparent" });
        }

        lastlastScrollPos = currentScrollPos;

        let maxScroll = document.body.scrollHeight - window.innerHeight;

        if (currentScrollPos === 0) {
          lastlastScrollPos = 0;
          setStylesHeader(scrollBottomHeaderColor);
          setColor(grad ? whiteColorHeader : blackColorHeader);
        }

        if (currentScrollPos > 0 && currentScrollPos <= maxScroll) {
          setStylesBack({ ...stylesBack, opacity: "1" });
          setGradient({ display: "none" });
        } else {
          setStylesBack({ ...stylesBack, opacity: "0" });
          setGradient({ display: "block" });
        }
      };
    }

    if (openHamburger) {
      window.onscroll = function () {
        window.scrollTo(0, 0);
      };
    }
  }, [openHamburger]);

  const hadleDropDownHeader = (e) => {
    if (e.target.ariaExpanded === "false") {
      setStylesHeader(dropDownHeader);
      setGradient({ display: "none" });
      setColor(blackColorHeader);
      setOpen(true);
    } else {
      if (window.pageYOffset > 100) {
        setColor(blackColorHeader);
        setStylesHeader(scrollTopHeaderColor);
      } else {
        setColor(grad ? whiteColorHeader : blackColorHeader);
        setStylesHeader(transparentHeader);
      }
      setOpen(false);
    }
  };

  const closeMenu = {
    height: "0px",
    display: "none",
  };

  const openMenu = {
    height: "100vh",
    display: "block",
  };

  return (
    <>
      <header className="header" id="header" style={stylesHeader}>
        <div
          className={openHamburger ? "bg-gradient-movil" : "bg-gradient"}
          style={grad ? gradient : { display: "none" }}
        ></div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>DAVID RACCUGLIA</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          ></meta>
        </Helmet>
        <div className="header-content">
          <div className="item">
            <Link
              className="hero"
              to="/"
              style={{ ...color, textDecoration: "none" }}
            >
              DAVID RACCUGLIA
            </Link>
          </div>
          <div className="item dropdown">
            <Dropdown onClick={hadleDropDownHeader} show={open}>
              <Dropdown.Toggle
                variant="success"
                id="dropdown"
                style={color}
                className="black"
              >
                {path}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {dropDownItem
                  .filter((e) => {
                    return e.label !== path;
                  })
                  .map((e) => {
                    return (
                      <Dropdown.Item>
                        <Link
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "16px",
                          }}
                          to={e.to}
                        >
                          {e.label}
                        </Link>
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>{" "}
          </div>
          <div className="item menu">
            <Hamburger
              size={22}
              className="hamburger"
              toggled={openHamburger}
              toggle={setOpenHamburger}
              color={color.color}
            />
          </div>
        </div>
      </header>

      <div style={openHamburger ? openMenu : closeMenu} className="menuMovil">
        <ListGroup variant="flush">
          {dropDownItem.map((e) => {
            return (
              <ListGroup.Item>
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none",
                    fontSize: "16px",
                  }}
                  to={e.to}
                >
                  {e.label}
                </Link>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>

      <button onClick={goBackUp} style={stylesBack} className="backToTop">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="gray"
          className="bi bi-arrow-up-circle"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
          />
        </svg>
      </button>
    </>
  );
};

export default Header;
